import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Message, MessageRole } from './chat.types';
import { ChatApiService } from './chat-api.service';
import { MarkdownService } from './chat-markdown.service';

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.scss'],
})
export class ChatContainerComponent implements OnInit, OnDestroy {
  @ViewChild('message_container') private messageContainer?: ElementRef;
  private _subscription: Subscription[] = [];
  private _id: string | null = null;
  public message: string = '';
  public messages: Message[] = [];
  public loadingChat: boolean = false;
  public loadingMessage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private chatApiService: ChatApiService,
    private router: Router,
    public markdownService: MarkdownService,
  ) {}

  ngOnInit() {
    console.log('ngOnInit');
    this.watchParams();
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this._subscription.forEach((sub) => sub.unsubscribe());
  }

  private watchParams() {
    this._subscription.push(
      this.route.paramMap.subscribe((params) => this._setId(params.get('id'))),
    );
  }

  public isAPIActive(): boolean {
    return this.loadingMessage || this.loadingChat;
  }

  public sendActive(): boolean {
    return !this.isAPIActive() && this.message.trim().length > 0;
  }

  public async inputKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await this.sendMessage();
    }
  }

  public async sendMessage() {
    if (!this._id) {
      console.log('no id');

      return;
    }
    this.loadingMessage = true;
    const message = this.message.trim();
    this.message = '';
    console.log('message', message);
    this._addMessage('user', message);
    const response = await this.chatApiService.sendMessage(this._id, message);
    if (response) {
      this._addMessage('assistant', response.response);
    } else {
      this._addMessage('error', 'Chat not found');
    }
    this.loadingMessage = false;
  }

  private async _setId(id: string | null) {
    console.log('_setId', id);

    if (id) {
      await this._loadChat(id);
    } else {
      await this._newChat();
    }
  }

  private async _newChat() {
    this.loadingChat = true;
    console.log('_newChat');
    this.messages = [];

    const response = await this.chatApiService.createChat();
    await this.setChatId(response.id);
    this._addMessage('assistant', response.response);

    this.loadingChat = false;
  }

  private async _loadChat(id: string) {
    console.log('_loadChat', id);

    if (this._id === id) {
      console.log('same chat');
      return;
    }

    await this.setChatId(id);

    this.messages = [];
    console.log('load previous messages');
    try {
      this.loadingChat = true;
      const response = await this.chatApiService.getChat(id);
      this.loadingChat = false;

      if (response) {
        response.forEach((message) => {
          this._addMessage(message.role, message.content);
        });
      } else {
        await this.setChatId();
      }
    } catch (error) {
      console.log('error loading existing chat', error);
    }
  }

  public async setChatId(id?: string) {
    if (id) {
      this._id = id;
      await this.router.navigate([`/chat/${this._id}`], {
        skipLocationChange: false,
        replaceUrl: true,
      });
    } else {
      this._id = null;
      await this.router.navigate([`/chat`], {
        skipLocationChange: false,
        replaceUrl: true,
      });
    }
  }

  private _addMessage(role: MessageRole, message: string): void {
    this.messages.push({ role, content: message });
    setTimeout(() => this.scrollToBottom(), 5);
  }

  private scrollToBottom(): void {
    if (!this.messageContainer) return;

    try {
      const element = this.messageContainer.nativeElement;
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    } catch (err) {
      console.error('error scrolling to bottom', err);
    }
  }
}
