import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatContainerComponent } from './chat-container/chat-container.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'chat', title: 'Autoist Chat', component: ChatContainerComponent },
  { path: 'chat/:id', title: 'Autoist Chat', component: ChatContainerComponent },
  { path: '**', title: 'Not Found', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
