import * as marked from 'marked';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MarkdownService {
    convertToHtml(markdown: string): string {
        return marked.parse(markdown);
    }
}
