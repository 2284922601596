import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, firstValueFrom, of } from 'rxjs';

import { Message } from './chat.types';

export interface ChatResponse {
  id: string;
  response: string;
}

const endpoints = {
  prod: 'https://api.demo.autoist.ai',
  local: 'http://localhost:8080',
};

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  private endpoint = endpoints.prod;

  constructor(private http: HttpClient) {}

  public createChat(): Promise<ChatResponse> {
    const url = `${this.endpoint}/chat`;
    return firstValueFrom(
      this.http.post<ChatResponse>(
        url,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        },
      ),
    );
  }

  public sendMessage(
    id: string,
    message: string,
  ): Promise<ChatResponse | null> {
    const url = `${this.endpoint}/chat/${id}`;
    return firstValueFrom(
      this.http
        .post<ChatResponse | null>(
          url,
          {
            message,
          },
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
          },
        )
        .pipe(catchError(() => of(null))),
    );
  }

  public getChat(id: string): Promise<Message[] | null> {
    const url = `${this.endpoint}/chat/${id}`;
    return firstValueFrom(
      this.http
        .get<Message[]>(url, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .pipe(catchError(() => of(null))),
    );
  }
}
