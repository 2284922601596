<p-panel>
  <ng-template pTemplate="header">
    <img src="/favicon.png" alt="Logo" style="width:30px; height:30px;">&nbsp;Autoist Chat
  </ng-template>

  <div *ngIf="loadingChat" class="loading-chat">
    Loading chat...
  </div>

  <div *ngIf="!loadingChat" #message_container class="message-container">
    <div *ngFor="let message of messages" class="message role-{{message.role}}">
      <p-card>
        <ng-template *ngIf="message.role === 'assistant'" pTemplate="header">
          <img alt="Card" src="/assets/chat_asistant_icon.png"/>&nbsp;&nbsp;Autoist
        </ng-template>
        <span [innerHTML]="markdownService.convertToHtml(message.content)"></span>
      </p-card>
    </div>
    <div *ngIf="loadingMessage" class="loading-message">
      Agent is typing...
    </div>
  </div>

  <div *ngIf="!loadingChat" class="send-container">
    <textarea pInputTextarea class="message-input" [(ngModel)]="message" rows="3"
              (keyup)="inputKeyUp($event)"></textarea>
    <button pButton class="new-chat" (click)="setChatId()">New Chat</button>
    <button pButton class="message-send" [disabled]="!sendActive()" (click)="sendMessage()">Send</button>
  </div>

</p-panel>
